import axios from "../../common/utils/axios";
import { OrderDetailsDefinition } from "../definitions/OrderDetailsDefinition";
import { OrderSummaryDefinition } from "../definitions/OrderSummaryDefinition";

export interface PostNewOrderAPIPayload {
  dueDate?: Date;
  products: {
    id: number;
    quantity: number;
    properties: Record<number, string>;
  }[];
  userId: number;
}

export interface PostNewOrdeFromWPAPIPayload {
  captchaToken: string | null;
  connectionFullName: string;
  connectionEmail: string;
  connectionPhoneNumber: string;
  products: {
    id: number;
    name: string;
    quantity: number;
    properties: Record<number, string>;
  }[];
}

interface PostNewQuoteProductQuoteDefinition {
  productId: number;
  totalAmount: number;
  estimatedDelivery: string;
  additionalDetails: string;
}

export interface PostNewQuoteAPIPayload {
  vendorName: string;
  productQuotes: PostNewQuoteProductQuoteDefinition[];
}

export const getAllOrdersAPI = () => {
  return axios.get<{ orders: OrderSummaryDefinition[] }>("/orders");
};

export const getOrderDetailsAPI = (orderId: string) => {
  return axios.get<OrderDetailsDefinition>(`/order/${orderId}`);
};

export const postNewOrderAPI = (payload: PostNewOrderAPIPayload) => {
  return axios.post("/order", payload);
};

export const postNewOrderFromWPAPI = (payload: PostNewOrdeFromWPAPIPayload) => {
  return axios.post("/wp/registerAndOrder", payload);
};

export const postNewQuoteAPI = (
  orderId: number,
  payload: PostNewQuoteAPIPayload
) => {
  return axios.post(`/order/${orderId}/quote`, payload);
};

export const patchApproveQuoteAPI = (orderId: string, quoteId: string) => {
  return axios.patch(`/order/${orderId}/quote/${quoteId}/approve`);
};
