import React from "react";
import {
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  FormHelperText,
  useTheme,
} from "@mui/material";

export interface KvkkConsentProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  error?: boolean;
}

export const KvkkConsent: React.FC<KvkkConsentProps> = ({
  checked,
  onChange,
  error = false,
}) => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();

  const handleOpenDialog = () => setOpen(true);
  const handleCloseDialog = () => setOpen(false);

  return (
    <Box>
      <FormControlLabel
        sx={{
          "& .MuiFormControlLabel-asterisk": {
            display: "none",
          },
        }}
        control={
          <Checkbox
            checked={checked}
            onChange={(e) => onChange(e.target.checked)}
            color="primary"
            required
            sx={{
              "&.Mui-checked": {
                color: error
                  ? theme.palette.error.main
                  : theme.palette.primary.main,
              },
            }}
          />
        }
        label={
          <Box display="flex" alignItems="center" flexWrap="wrap">
            <Typography
              variant="body2"
              color={error ? "error" : "textSecondary"}
              sx={{ mr: 1 }}
            >
              KVKK metnini okudum ve onaylıyorum.
            </Typography>
            <Button
              onClick={handleOpenDialog}
              size="small"
              sx={{
                minWidth: "auto",
                textTransform: "none",
                p: "2px 8px",
                fontSize: "0.75rem",
              }}
            >
              (Metni Oku)
            </Button>
            <Typography
              component="span"
              variant="body2"
              color="error"
              sx={{ ml: 0.5 }}
            >
              *
            </Typography>
          </Box>
        }
      />

      {error && (
        <FormHelperText
          error
          sx={{
            ml: 2,
            mt: 0.5,
          }}
        >
          KVKK metnini onaylamanız gerekmektedir.
        </FormHelperText>
      )}

      <Dialog
        open={open}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
        scroll="paper"
      >
        <DialogTitle
          sx={{
            borderBottom: `1px solid ${theme.palette.divider}`,
            pb: 2,
          }}
        >
          Kişisel Verilerin Korunması Aydınlatma Metni
        </DialogTitle>

        <DialogContent>
          <Box sx={{ py: 2 }}>
            <Typography variant="body2" paragraph>
              66698 sayılı Kişisel Verilerin Korunması Kanunu (“Kanun”)
              uyarınca, kişisel verileriniz veri sorumlusu sıfatıyla hareket
              eden Paketera Ambalaj Platformu (“Paketera” veya “Şirket”)
              tarafından aşağıda açıklanan kapsamda işlenebilecektir.
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ mt: 2, mb: 1, fontWeight: "bold" }}
            >
              1. Kişisel Verilerin Hangi Amaçlarla İşleneceği ve Hukuki
              Sebepleri
            </Typography>
            <Typography variant="body2" paragraph>
              Toplanan kişisel verileriniz Paketera.com (“Platform”)
              kullanımınız kapsamında aşağıda yer verilen amaçlar (“Amaçlar”) ve
              hukuki sebepler ile Kanun’un 5. maddesinde belirtilen kişisel veri
              işleme şartları ve amaçları dahilinde işlenebilecektir. Bir
              sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması
              kaydıyla, sözleşmenin taraflarına ait kişisel verilerin
              işlenmesinin gerekli olması ve veri sorumlusunun hukuki
              yükümlülüğünü yerine getirebilmesi için zorunlu olması hukuki
              sebebine dayalı olarak; · Platform hesabınızın oluşturulması,
              doğrulamaların yapılması ve işletilmesi, · Platform’un hizmet ve
              işlevlerinden faydalandırılmanız doğrultusunda iş birimleri
              tarafından gerekli iş süreçlerinin yürütülmesi, · Platform’a
              erişim / kullanıcı oluşturma talebinde bulunan çalışanı/yetkilisi
              olduğunuz kuruluşun başvurularının değerlendirilmesi ve
              sonuçlandırılması, · Gerekli hallerde Platform üzerinden sipariş
              oluşturulması, siparişlerin takibi ve teslimat
              girişlerinin-işlemlerinin yapılması, fatura ön kayıtlarının
              oluşturulması, faturaların görüntülenmesi ve mutabakat için
              gerekli süreçlerin işletilmesi, · Şirketimizle çalışanı/yetkilisi
              olduğunuz firma arasındaki iş ilişkisi kapsamındaki edimlerin
              yerine getirilmesi, · Ürün ve hizmetlerin Platform üzerinden
              sunulmasına yönelik çalışmaların gerçekleştirilmesi. · Ürün talep
              süreçlerinin yönetilmesi
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ mt: 2, mb: 1, fontWeight: "bold" }}
            >
              2. İlgili kişinin temel hak ve özgürlüklerine zarar vermemek
              kaydıyla, veri sorumlusunun meşru menfaatleri için veri
              işlenmesinin zorunlu olması hukuki sebebine dayalı olarak;
            </Typography>
            <Typography variant="body2" paragraph>
              Platform’un sunduğu fonksiyonlardan faydalanmanızın sağlanması
              amacıyla gerekli iş ve operasyonel süreçlerin yürütülmesi, ·
              Platform’un işletilmesi ile ilgili bilgi teknolojileri
              süreçlerinin iş birimleri tarafından yönetilmesi, · Platform’un
              yönetilmesi, geliştirilmesi ve iyileştirilmesine yönelik
              süreçlerin yürütülmesi, · Erişim yetkileri ve kullanıcı tanımlama
              iş süreçlerinin yürütülmesi, · Hukuki işlerinin takibi ve
              yürütülmesi, · Bilgi güvenliği ve teknolojileri süreçlerinin
              yönetimi ile siber güvenliğin sağlanması, · Şirketimiz tarafından
              yürütülen ticari faaliyetlerin gerçekleştirilmesi için ilgili iş
              birimlerimiz tarafından gerekli çalışmaların yapılması ve buna
              bağlı iş süreçlerinin yürütülmesi,Talep ve şikayetlerin alınması
              ve sonuçlandırılması.
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ mt: 2, mb: 1, fontWeight: "bold" }}
            >
              3. Kanunlarca açıkça öngörülmesi ve veri sorumlusunun hukuki
              yükümlülüğünü yerine getirebilmesi için zorunlu olması hukuki
              sebebine dayalı olarak;
            </Typography>
            <Typography variant="body2" paragraph>
              Resmi kurum taleplerinin yerine getirilmesi, · Yetkili kuruluşlara
              mevzuattan kaynaklı bilgi verilmesi, · Mevzuatta öngörülen saklama
              yükümlülüklerine uygunluğun sağlanması, · İlgili kişi
              başvurularının mevzuata uygun olarak yanıtlanması ve gerekli
              işlemlerin gerçekleştirilmesi.
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ mt: 2, mb: 1, fontWeight: "bold" }}
            >
              4. Bir hakkın tesisi, kullanılması veya korunması için veri
              işlemenin zorunlu olması hukuki sebebine dayalı olarak;
            </Typography>
            <Typography variant="body2" paragraph>
              Talep ve şikayetlerinizin alınması ve sonuçlandırılması, · İleride
              meydana gelmesi muhtemel olası uyuşmazlıklarda delil teşkil etmesi
              amacı ile kişisel verilerin genel zamanaşımı süresince saklanması.
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ mt: 2, mb: 1, fontWeight: "bold" }}
            >
              5. Açık rızanızın varlığı halinde ise,
            </Typography>
            <Typography variant="body2" paragraph>
              Kimlik, temsilcisi olduğunuz şirket bilgileri (adı, sektörü,
              çalışan sayısı) iletişim ve işlem verilerinizin Şirketimizin ürün
              ve hizmetlerinin pazarlama süreçlerinin gerçekleştirilmesi,
              profilleme ve analiz faaliyetleri dahil Şirketimiz tarafından
              sunulan ürün ve hizmetlerin ilgili kişilerin beğeni, kullanım
              alışkanlıkları ve ihtiyaçlarına göre özelleştirilerek ilgili
              kişilere önerilmesi ve tanıtılması amacıyla işlenmesi, · Kimlik ve
              iletişim verilerinizin tercih ettiğiniz iletişim bilgilerinize
              ticari elektronik ileti gönderilmesi amacıyla işlenmesi.
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ mt: 2, mb: 1, fontWeight: "bold" }}
            >
              6. İşlenen Kişisel Verilerin Kimlere ve Hangi Amaçla
              Aktarılabileceği
            </Typography>
            <Typography variant="body2" paragraph>
              Toplanan kişisel verileriniz; yukarıda belirtilen Amaçlar’ın
              gerçekleştirilmesi doğrultusunda, meşru menfaatimiz kapsamında
              hizmet aldığımız tedarikçilerimize, kanunlarda açıkça öngörülmesi
              ve hukuki yükümlülüklerimizin yerine getirilmesi kapsamında
              kanunen yetkili kamu kurumlarına ve kanunen yetkili özel kişilere;
              açık rızanızı vermeniz halinde, kimlik, iletişim, finansal bilgi
              ve müşteri işlem verileriniz, Şirketimiz tarafından barındırma
              amacıyla hizmet alınan yurt dışındaki hizmet sağlayıcıya Kanun’un
              5. maddesinde düzenlenen veri işleme şartları çerçevesinde
              Kanun’un 8. ve 9. maddelerinde belirtilen kişisel verilerin
              aktarılmasına ilişkin kurallara uygun olarak aktarılabilecektir.
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ mt: 2, mb: 1, fontWeight: "bold" }}
            >
              7. Kişisel Veri Toplamanın Yöntemi
            </Typography>
            <Typography variant="body2" paragraph>
              Kişisel verileriniz, yukarıda yer verilen Amaçlar’ın yerine
              getirilmesi kapsamında elektronik ortamda Platform, çağrı merkezi,
              telefon, kısa mesaj e-posta, fiziki ortamda Şirketimiz ile
              iletişime geçtiğiniz hallerde gerçekleştirilen görüşmeler, formlar
              vasıtasıyla ve çevrimiçi formlar üzerinden toplanmaktadır.
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ mt: 2, mb: 1, fontWeight: "bold" }}
            >
              8. İlgili Kişinin Kanun’un 11. maddesinde Sayılan Hakları
            </Typography>
            <Box component="ul" sx={{ pl: 2, mt: 1 }}>
              Kişisel verilerinize yönelik olarak Kanun’un 11. maddesi uyarınca
              aşağıdaki haklara sahip olduğunuzu bildiririz:
              {[
                "Kişisel verilerinizin işlenip işlenmediğini öğrenme",
                "Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme",
                "Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme",
                "Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü kişileri bilme",
                "Kişisel verilerinizin eksik veya yanlış işlenmiş olması halinde bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme",
                "Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması halinde kişisel verilerinizin silinmesini veya yok edilmesini isteme ve bu kapsamda ve kişisel verilerinizin eksik veya yanlış işlenmiş olması halinde yapılan işlemlerin kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme",
                "İşlenen verilerinizin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhinize bir sonucun ortaya çıkmasına itiraz etme,",
                "Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız halinde zararın giderilmesini talep etme.",
              ].map((hak, index) => (
                <Typography
                  key={index}
                  variant="body2"
                  component="li"
                  sx={{ mb: 0.5 }}
                >
                  {hak}
                </Typography>
              ))}
            </Box>
            <Typography
              variant="subtitle1"
              sx={{ mt: 3, mb: 1, fontWeight: "bold" }}
            >
              9. Başvuru Süreci
            </Typography>
            <Typography variant="body2" paragraph>
              Yukarıda sıralanan haklarınıza yönelik başvurularınızı,
              destek@paketera.com e-posta adresine aşağıdaki bilgileri (FORM-A)
              doldurarak Şirketimize iletebilirsiniz. Talebinizin niteliğine
              göre en kısa sürede ve en geç otuz gün içinde başvurularınız
              ücretsiz olarak sonuçlandırılacaktır; ancak işlemin ayrıca bir
              maliyet gerektirmesi halinde Kişisel Verileri Koruma Kurulu
              tarafından belirlenecek tarifeye göre tarafınızdan ücret talep
              edilebilecektir.
            </Typography>
            <Typography variant="subtitle2" sx={{ mt: 2, fontWeight: "bold" }}>
              FORM-A: İLGİLİ KİŞİ BAŞVURU FORMU
            </Typography>
            <Typography
              variant="body2"
              paragraph
              sx={{ fontStyle: "italic", mt: 1 }}
            >
              Not: E-posta'nın konu kısmına "Kişisel Verilerin Korunması Kanunu
              Bilgi Talebi" yazılacaktır.
            </Typography>
            {/* Form Bölümleri */}
            <Typography variant="subtitle2" sx={{ mt: 2, fontWeight: "bold" }}>
              1. Kimlik ve İletişim Bilgileri
            </Typography>
            <Box component="ul" sx={{ listStyle: "none", pl: 0 }}>
              {[
                "Ad Soyad",
                "T.C. Kimlik Numarası / Diğer Ülke Vatandaşları için Pasaport Numarası veya Kimlik Numarası",
                "Tebligata Esas Yerleşim Yeri Adresi / İş Yeri Adresi",
                "Cep Telefonu (GSM)",
                "Telefon Numarası",
                "Faks Numarası",
                "E-posta Adresi",
              ].map((item, index) => (
                <Typography
                  key={index}
                  variant="body2"
                  component="li"
                  sx={{ mb: 1 }}
                >
                  {item}
                </Typography>
              ))}
            </Box>
            <Typography variant="subtitle2" sx={{ mt: 2, fontWeight: "bold" }}>
              2. Paketera ile İlişkiniz
            </Typography>
            <Box component="ul" sx={{ pl: 2 }}>
              {[
                "Müşteri (Alıcı)",
                "Müşteri (Satıcı)",
                "Çalışan",
                "Eski Çalışan",
                "Çalışan Adayı",
              ].map((item, index) => (
                <Typography
                  key={index}
                  variant="body2"
                  component="li"
                  sx={{ mb: 0.5 }}
                >
                  {item}
                </Typography>
              ))}
            </Box>
            <Typography variant="subtitle2" sx={{ mt: 2, fontWeight: "bold" }}>
              3. Talep Konusu
            </Typography>
            <Typography variant="body2" paragraph>
              Kişisel verilerinize ilişkin talebinizi açıkça yazmanızı rica
              ederiz. Konuya ilişkin bilgi ve belgeler başvuruya eklenmelidir.
            </Typography>
            <Typography variant="body2" paragraph sx={{ mt: 2 }}>
              Yukarıda belirttiğim talepler doğrultusunda, Şirketinize yapmış
              olduğum başvurumun Kanun'un 13. maddesi uyarınca değerlendirilerek
              tarafıma bilgi verilmesini rica ederim.
            </Typography>
            <Typography variant="body2" paragraph>
              İşbu başvuruda tarafınıza sağlamış olduğum bilgi ve belgelerimin
              doğru ve güncel olduğunu, Şirketinizin başvurumu
              sonuçlandırabilmek adına ilave bilgi talep edebileceğini ve ayrıca
              bir maliyet gerektirmesi halinde Kişisel Verileri Koruma Kurulu
              tarafından belirlenen ücreti ödemem gerekebileceği hususunda
              aydınlatıldığımı beyan ve taahhüt ederim.
            </Typography>
            <Typography variant="subtitle2" sx={{ mt: 2, fontWeight: "bold" }}>
              Başvuruda Bulunan İlgili Kişi
            </Typography>
            <Box sx={{ pl: 2, mt: 1 }}>
              <Typography variant="body2">Adı Soyadı:</Typography>
              <Typography variant="body2">Başvuru Tarihi:</Typography>
              <Typography variant="body2">İmza:</Typography>
            </Box>
          </Box>
        </DialogContent>

        <DialogActions
          sx={{ p: 2, borderTop: `1px solid ${theme.palette.divider}` }}
        >
          <Button onClick={handleCloseDialog} variant="contained" size="small">
            Kapat
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
