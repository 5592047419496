import axios from "../../common/utils/axios";
import { ProductPropertyDefinition } from "../definitions/ProductPropertyDefinition";
import { ProductsListItemDefinition } from "../definitions/ProductsListItemDefinition";

export const getAllProductsAPI = () => {
  return axios.get<{ products: ProductsListItemDefinition[] }>("/products");
};

export const getProductPropertiesAPI = (productId: number) => {
  return axios.get<{
    productId: number;
    properties: ProductPropertyDefinition[];
  }>(`/productProperties/${productId}`);
};
