import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ProfilePage } from "./modules/auth/pages/ProfilePage";
import { ProtectedPage } from "./modules/auth/components/ProtectedPage";
import { AuthProvider } from "./modules/auth/contexts/AuthContext";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import { SnackbarProvider } from "notistack";
import { WpFormPage } from "./modules/common/pages/WpFormPage";

// const LandingPage = React.lazy(() =>
//   import("./modules/common/pages/LandingPage").then((module) => ({
//     default: module.LandingPage,
//   }))
// );
const LoginPage = React.lazy(() =>
  import("./modules/auth/pages/LoginPage").then((module) => ({
    default: module.LoginPage,
  }))
);
const RegisterPage = React.lazy(() =>
  import("./modules/auth/pages/RegisterPage").then((module) => ({
    default: module.RegisterPage,
  }))
);
const AdminRoutes = React.lazy(() =>
  import("./modules/admin/routes").then((module) => ({
    default: module.AdminRoutes,
  }))
);
const CustomerRoutes = React.lazy(() =>
  import("./modules/customer/routes").then((module) => ({
    default: module.CustomerRoutes,
  }))
);
const ForgotPasswordPage = React.lazy(() =>
  import("./modules/auth/pages/ForgotPasswordPage").then((module) => ({
    default: module.ForgotPasswordPage,
  }))
);
const ForgotPasswordSuccessPage = React.lazy(() =>
  import("./modules/auth/pages/ForgotPasswordSuccessPage").then((module) => ({
    default: module.ForgotPasswordSuccessPage,
  }))
);
const ResetPasswordPage = React.lazy(() =>
  import("./modules/auth/pages/ResetPasswordPage").then((module) => ({
    default: module.ResetPasswordPage,
  }))
);

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <SnackbarProvider maxSnack={3}>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <BrowserRouter>
              <Routes>
                {/* TODO - Landing page implementation? */}
                <Route path="/" element={<LoginPage />} />
                <Route path="/giris" element={<LoginPage />} />
                <Route path="/kayit" element={<RegisterPage />} />
                <Route
                  path="/sifremi-unuttum"
                  element={<ForgotPasswordPage />}
                />
                <Route
                  path="/sifremi-unuttum/basarili"
                  element={<ForgotPasswordSuccessPage />}
                />
                <Route
                  path="/sifre-sifirlama/:token"
                  element={<ResetPasswordPage />}
                />
                <Route path="/wp-form" element={<WpFormPage />} />
                <Route
                  path="/profil"
                  element={
                    <ProtectedPage>
                      <ProfilePage />
                    </ProtectedPage>
                  }
                />
                <Route
                  path="/musteri/*"
                  element={
                    <ProtectedPage userType="customer">
                      <CustomerRoutes />
                    </ProtectedPage>
                  }
                />
                <Route
                  path="/yonetici/*"
                  element={
                    <ProtectedPage userType="admin">
                      <AdminRoutes />
                    </ProtectedPage>
                  }
                />
              </Routes>
            </BrowserRouter>
          </AuthProvider>
        </ThemeProvider>
      </SnackbarProvider>
    </Suspense>
  );
}

export default App;
