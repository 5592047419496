import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import { useAuth } from "../../contexts/AuthContext";
import { getUserPathFromUserType } from "../../utils/authUtils";

interface Props {
  children: React.ReactNode;
  userType?: "admin" | "customer";
}

export const ProtectedPage = ({ children, userType }: Props): JSX.Element => {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.isAuthChecked) {
    return <div>Loading...</div>;
  }

  if (!auth.user) {
    return <Navigate to="/giris" state={{ from: location }} replace />;
  }

  if (userType && auth.user.role !== userType) {
    return (
      <Navigate
        to={getUserPathFromUserType(auth.user.role)}
        state={{ from: location }}
        replace
      />
    );
  }

  return <>{children}</>;
};
