import axios from "axios";
import { USER_TOKEN_STORAGE_KEY } from "../../auth/contexts/AuthContext";

axios.interceptors.request.use((config) => {
  config.baseURL =
    process.env.NODE_ENV === "development"
      ? ""
      : process.env.REACT_APP_SERVICE_ENDPOINT_URL;

  const authToken = sessionStorage.getItem(USER_TOKEN_STORAGE_KEY);
  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }

  return config;
});

export default axios;
