import React, { useEffect, useMemo, useState } from "react";
import {
  Autocomplete,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid2,
  IconButton,
  TextField,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import { ProductsListItemDefinition } from "../../definitions/ProductsListItemDefinition";
import { ProductDefinition } from ".";
import { ProductPropertyDefinition } from "../../definitions/ProductPropertyDefinition";
import { getProductPropertiesAPI } from "../../api/ProductsAPI";
import { enqueueSnackbar } from "notistack";
import { useFormikContext } from "formik";

interface Props {
  availableProducts: ProductsListItemDefinition[];
  index: number;
  onProductRemove: () => void;
  product: ProductDefinition & { uid: string };
}

const NewOrderProductCard = ({
  availableProducts,
  index,
  onProductRemove,
  product,
}: Props) => {
  const [productProperties, setProductProperties] = useState<
    ProductPropertyDefinition[]
  >([]);

  const selectedProduct = useMemo(
    () =>
      availableProducts.find(
        (p) => p.product_id === Number(product.productId) || null
      ),
    [availableProducts, product]
  );

  const { setFieldValue, handleChange } = useFormikContext<{
    products: ProductDefinition[];
  }>();

  const productPropertyInputFields = useMemo(() => {
    return productProperties.map((productProperty) => {
      switch (productProperty.property_type) {
        case "number":
          return (
            <TextField
              key={`${product.uid}_properties_${productProperty.property_id}`}
              fullWidth
              required
              label={productProperty.property_name}
              name={`products.${index}.properties.${productProperty.property_id}`}
              onChange={handleChange}
              slotProps={{ htmlInput: { min: 0 } }}
              type="number"
              value={product.properties[productProperty.property_id]}
            />
          );
        case "single_option":
          return (
            <Autocomplete
              key={`${product.uid}_properties_${productProperty.property_id}`}
              fullWidth
              onChange={(_, value) =>
                setFieldValue(
                  `products.${index}.properties.${productProperty.property_id}`,
                  value || ""
                )
              }
              options={productProperty.available_options || []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={productProperty.property_name}
                  required
                />
              )}
              value={product.properties[productProperty.property_id]}
            />
          );
        case "yes_no":
          return (
            <FormControlLabel
              key={`${product.uid}_properties_${productProperty.property_id}`}
              control={
                <Checkbox
                  checked={
                    product.properties[productProperty.property_id] === "Evet"
                  }
                  onChange={(_, checked) =>
                    setFieldValue(
                      `products.${index}.properties.${productProperty.property_id}`,
                      checked ? "Evet" : "Hayır"
                    )
                  }
                />
              }
              label={productProperty.property_name}
            />
          );
        default:
          return null;
      }
    });
  }, [setFieldValue, productProperties, index, handleChange, product]);

  useEffect(() => {
    if (product.productId) {
      getProductPropertiesAPI(Number(product.productId))
        .then(({ data }) => {
          setProductProperties(data.properties);
        })
        .catch(() => {
          setProductProperties([]);
          enqueueSnackbar("Ürün alanları gösterilirken bir hata oluştu.", {
            variant: "error",
          });
        });
    } else {
      setProductProperties([]);
    }
  }, [product.productId]);

  return (
    <Card>
      <CardHeader
        action={
          <Box display="flex" gap={1}>
            <IconButton
              aria-label="delete"
              size="small"
              onClick={onProductRemove}
              title="Sil"
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </Box>
        }
      />
      <CardContent>
        <Grid2 container direction="column" spacing={1}>
          <Autocomplete
            key={`${product.uid}_product`}
            fullWidth
            getOptionLabel={(option) => option.product_name}
            groupBy={(option) => option.category}
            onChange={(_, value) =>
              setFieldValue(
                `products.${index}.productId`,
                value?.product_id || ""
              )
            }
            options={availableProducts.sort(
              (a, b) =>
                -b.category.localeCompare(a.category) ||
                -b.product_name.localeCompare(a.product_name)
            )}
            renderInput={(params) => {
              return <TextField {...params} label="Ürün" required />;
            }}
            value={selectedProduct}
          />
          <TextField
            fullWidth
            required
            label="Adet"
            name={`products.${index}.quantity`}
            onChange={handleChange}
            value={product.quantity}
            slotProps={{ htmlInput: { min: 0 } }}
            type="number"
          />
          {productPropertyInputFields}
          <TextField
            fullWidth
            label="Açıklama"
            name={`products.${index}.additionalDetails`}
            onChange={handleChange}
            value={product.additionalDetails}
          />
        </Grid2>
      </CardContent>
    </Card>
  );
};

const memoized = React.memo(NewOrderProductCard);
export { memoized as NewOrderProductCard };
