import { Suspense, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";
import MenuItem from "@mui/material/MenuItem";

import { useAuth } from "../../../auth/contexts/AuthContext";
import { stringToColoredAvatar } from "../../utils/string";

import paketeraLogo from "../../../../assets/images/paketera_logo_1000x240.png";

import styles from "./styles.module.css";

interface PageLayoutProps {
  children: React.ReactNode;
}

export const PageLayout = ({ children }: PageLayoutProps) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateToProfile = () => {
    navigate("/profil");
    handleClose();
  };

  const signout = () => {
    auth.signout(() => {
      navigate("/giris");
    });
  };

  return (
    <Box sx={{ flexGrow: 1, paddingTop: 10 }}>
      <AppBar position="fixed" style={{ backgroundColor: "white" }}>
        <Toolbar>
          <div style={{ flexGrow: 1 }} onClick={() => navigate("/")}>
            <img
              alt="Paketera logo"
              src={paketeraLogo}
              style={{ cursor: "pointer", maxHeight: 36 }}
            />
          </div>
          <Avatar
            {...stringToColoredAvatar(auth.user?.fullname || " ")}
            onClick={handleMenu}
          />
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem divider>{auth.user?.fullname}</MenuItem>
            <MenuItem onClick={navigateToProfile}>Profil</MenuItem>
            <MenuItem onClick={signout}>Çıkış yap</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Suspense fallback={<div>Loading...</div>}>
        <div className={styles.Page}>{children}</div>
      </Suspense>
    </Box>
  );
};
