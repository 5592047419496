import React, { createContext, useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import {
  signinAPI,
  signupAPI,
  handleForgotPasswordAPI,
} from "../hooks/AuthAPI";

import { UserDefinition } from "../definitions/UserDefinition";
import { enqueueSnackbar } from "notistack";

export const USER_TOKEN_STORAGE_KEY =
  process.env.REACT_APP_USER_TOKEN_STORAGE_KEY || "paketera_user";

interface AuthContextType {
  isAuthChecked: boolean;
  user: UserDefinition | null;
  signin: (
    email: string,
    password: string,
    callback: (userData: UserDefinition) => void
  ) => void;
  signout: (callback: VoidFunction) => void;
  signup: (
    fullName: string,
    email: string,
    password: string,
    passwordConfirmation: string,
    callback: () => void
  ) => void;
  sendPasswordResetEmail: (email: string) => Promise<void>;
}

let AuthContext = createContext<AuthContextType>(null!);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<UserDefinition | null>(null);
  const [isAuthChecked, setIsAuthChecked] = useState(false);

  const signin = (
    email: string,
    password: string,
    callback: (userData: UserDefinition) => void
  ) => {
    return signinAPI(email, password)
      .then(({ data }) => {
        const userData = jwtDecode(data.token) as UserDefinition;
        setUser(userData);
        sessionStorage.setItem(USER_TOKEN_STORAGE_KEY, data.token);
        callback(userData);
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.response.data.error, { variant: "error" });
      });
  };

  const sendPasswordResetEmail = async (email: string): Promise<void> => {
    try {
      await handleForgotPasswordAPI(email);
      enqueueSnackbar(
        "Şifre sıfırlama bağlantısı email adresinize gönderildi.",
        {
          variant: "success",
        }
      );
    } catch (err) {
      console.error("Password reset error:", err);

      let errorMessage = "Şifre sıfırlama işlemi sırasında bir hata oluştu.";

      if (err && typeof err === "object" && "response" in err) {
        const error = err as { response?: { data?: { error?: string } } };
        errorMessage = error.response?.data?.error || errorMessage;
      }

      enqueueSnackbar(errorMessage, {
        variant: "error",
      });
      throw err;
    }
  };

  const signout = (callback: VoidFunction) => {
    setUser(null);
    sessionStorage.removeItem(USER_TOKEN_STORAGE_KEY);
    callback();
  };

  const signup = (
    fullName: string,
    email: string,
    password: string,
    passwordConfirmation: string,
    callback: () => void
  ) => {
    return signupAPI(fullName, email, password, passwordConfirmation)
      .then(() => {
        callback();
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.response.data.error, { variant: "error" });
      });
  };

  useEffect(() => {
    const sessionUserData = sessionStorage.getItem(USER_TOKEN_STORAGE_KEY);

    let decodedJwt;
    try {
      decodedJwt = jwtDecode(sessionUserData ?? "");
    } catch (error) {
      setUser(null);
      sessionStorage.removeItem(USER_TOKEN_STORAGE_KEY);
    }

    if (sessionUserData && decodedJwt) {
      setUser(decodedJwt as UserDefinition);
    }

    setIsAuthChecked(true);
  }, []);

  const value = {
    isAuthChecked,
    user,
    signin,
    signout,
    signup,
    sendPasswordResetEmail,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return React.useContext(AuthContext);
};
