import { Button, Typography } from "@mui/material";
import { PageLayout } from "../../common/components/PageLayout";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { UserDefinition } from "../definitions/UserDefinition";

export const ProfilePage = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const handleSignout = () => {
    auth.signout(() => {
      navigate("/giris");
    });
  };

  const userRole = (role: UserDefinition["role"] | undefined) => {
    switch (role) {
      case "admin":
        return "Yönetici";
      case "customer":
        return "Müşteri";
      default:
        return "Kullanıcı";
    }
  };

  return (
    <PageLayout>
      <Typography variant="h4">
        {auth.user?.fullname} - #{auth.user?.id}
      </Typography>
      <Typography variant="h6">Email: {auth.user?.email}</Typography>
      <Typography variant="h6">
        Kullanıcı türü: {userRole(auth.user?.role)}
      </Typography>
      <Button variant="outlined" color="error" onClick={handleSignout}>
        Çıkış yap
      </Button>
    </PageLayout>
  );
};
