export const getUserPathFromUserType = (userType: "admin" | "customer") => {
  switch (userType) {
    case "admin":
      return "/yonetici";
    case "customer":
      return "/musteri";
    default:
      return "/";
  }
};
